<template>
 <Suspense>
   <template #default>
     <AsyncListItems :getLocation="getLocation" />
   </template>
   <template #fallback>
     <span>Daten werden geladen ... Bitte warten</span>
   </template>
 </Suspense>
</template>

<script>
import AsyncListItems from '@/components/AsyncListItems.vue';

export default {
  name: 'List',
  components: { AsyncListItems },
  methods: {
    getLocation: function() {
      switch (this.$route.name) {
        case 'Krankheit':
          return ['physische_verortung?filter[status]=published'];
          break;
        case 'Erreger':
          return ['erreger?filter[status]=published'];
          break;
        case 'Medikament':
          return ['medikamente?filter[status]=published'];
          break;
        case 'diseases':
          return ['krankheiten?filter[status]=published&fields=*.*&filter[physische_verortung][physische_verortung_id]=', 'physische_verortung?filter[status]=published&filter[name]=' + this.$route.params.pythian_location];
          break;
        default:
          break;
      }
    }
  }
}
</script>