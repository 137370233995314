<template>
  <div v-if=isLogin()>
    <Navigation />
    <Breadcrumb />
    <List />
  </div>
  <div v-else>
    <Login />
  </div>
</template>

<script>
  // @ is an alias to /src
  import Navigation from "@/components/Navigation.vue";
  import Login from "@/components/Login.vue";
  import Breadcrumb from "@/components/Breadcrumb.vue";
  import List from "@/components/List.vue";
  import Mixin from "../mixin.js";

  export default {
    mixins: [Mixin],
    components: {
      Navigation,
      Login,
      Breadcrumb,
      List
    },
    created() {
      document.title = "Antibiotika App - Krankheit";
    },
    mounted() {
      this.$nextTick(() => {
        if (document.getElementById('disease') != null)
          document.getElementById('disease').classList.add('active');
      });
    }
  };
</script>
