<template>
  <main>
    <section>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-10">
            <div class="card">
              <div class="card-header">
                <h1 v-if="this.$route.params.pythian_location != undefined" class="h4 mt-2 ">{{this.$route.params.pythian_location.charAt(0).toUpperCase()+this.$route.params.pythian_location.slice(1)}}</h1>
                <h1 v-else class="h4 mt-2 ">{{this.$route.name}}</h1>
              </div>
              <div v-if="items!=null" class="list-group list-group-flush">
                <div v-if="this.$route.params.pythian_location != undefined">
                  <a v-for="item in items.data" :key="item.id" :id=item.id :href="this.$route.params.pythian_location + '/' + item.name.toLowerCase()" class="list-group-item list-group-item-action d-flex align-items-center"> {{item.name}} <i class="fas fa-chevron-right ml-auto"></i></a>
                </div>
                <div v-else>
                  <a v-for="item in items.data" :key="item.id" :id=item.id :href="this.$route.name.toLowerCase() + '/' + item.name.toLowerCase()" class="list-group-item list-group-item-action d-flex align-items-center"> {{item.name}} <i class="fas fa-chevron-right ml-auto"></i></a>
                </div>
              </div>
              <div v-else class="list-group list-group-flush">
                <p>Es ist ein Fehler beim Abfragen der Daten aufgetreten</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { onErrorCaptured, ref } from 'vue'

export default {
  name: 'AsyncListItems',
  props: ['getLocation'],
  async setup(props) {
    var token = document.cookie.match(new RegExp('(^| )' + 'directus_api_auth_token' + '=([^;]+)'));
    if (token) {
      const items = ref(null);
      if(props.getLocation().length < 2) {
        const response = await fetch('https://directus.comkom.de/items/'+ props.getLocation()[0], {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin', 
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token[2],
          },
          redirect: 'follow',
          referrerPolicy: 'no-referrer', 
        });
        if(response.ok) {
          items.value = await response.json();
          return {items};
        } else {
          return response;
        }
      } else {
        const response = await fetch('https://directus.comkom.de/items/'+ props.getLocation()[1], {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin', 
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token[2],
          },
          redirect: 'follow',
          referrerPolicy: 'no-referrer',
        });
        items.value = await response.json();

        const response2 = await fetch('https://directus.comkom.de/items/'+ props.getLocation()[0] + items.value.data[0].id, {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin', 
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token[2],
          },
          redirect: 'follow',
          referrerPolicy: 'no-referrer',
        });
        items.value = await response2.json();
        return {items};
      }
    }
  }
}
</script>